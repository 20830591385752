import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';
import {
  HonbunWrapper,
} from './templates.style';
import PostCard from '../components/post-card/post-card';
import Sticky from 'react-stickynode';
import Cityset1 from "../components/citysetting/citytitle1"
import Styles from "./blog-post.module.css"
import Hanrei from "../containers/hanrei2"
import CesiumContainer from "../pages/cesiumContainer10ref_sogo"
import Image from '../components/image';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  ); 

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};


const BlogPostTemplate = (props: any) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );
  const post = props.data.markdownRemark;
  const onChange = event => setValue(event.target.value);
  const onChange2 = event => setValue2(event.target.value);
  const city1 = {value};
  const city1nfd = city1.value.normalize( 'NFD' ) ;
  const kml_filenfd = post.frontmatter.kml_file.normalize( 'NFD' ) ;
  const { edges } = props.data.allMarkdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  // const shareUrl = urljoin(siteUrl, slug);
  // const kmlsrc='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  // const kmldown='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/福岡県糸島市_' + post.frontmatter.kml_file
  // const kmlsrc1='https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file

  const kml_kmz='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd
  
  const kml_kml='/kml/'+post.frontmatter.no+'/'+ city1nfd + '_' + kml_filenfd.substring(0, kml_filenfd.length-1) + 'l'

  // const kml_kmz='https://empire-dev3.kashika.net/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file
  
  // const kml_kml='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file.substring(0, post.frontmatter.kml_file.length-1) + 'l'

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };
  var filename="sdg_"+"all"+".png";
  console.log(filename);

  return (
    // <Layout3>
    <div>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      
    <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
      <div className={Styles.bar}>
        <div style={{display:"flex"}}>
          <div className={Styles.headerleft}>
            {/* <Cityset1 /> */}
              <Image filename={filename}/>
          </div>
            <div className={Styles.headertitle2}>
            SAAS入門
          </div>
          <input
            className={Styles.ef}
            value={value}
            type="text"
            placeholder="市町村名を入力、選択肢から選ぶ"
            onChange={onChange} 
            autocomplete="on"
            list="keywords"
          />の都市構造　（
          <input
            className={Styles.ef}
            value={value2}
            type="text"
            placeholder="市町村名を入力、選択肢から選ぶ"
            onChange={onChange2} 
            autocomplete="on"
            list="keywords"
          />と比較する）
          <div className={Styles.headerright}>
            <a className={Styles.button} href="/">HOME</a>
          </div>
        </div>
      </div>
    </Sticky>



      <div className={Styles.csstabgrid}>
      <div className={Styles.sogocsstabgridinner}>
        <div className={Styles.sogocsstabgridchild000}>
          <Link to={"/18/sogo"} className={Styles.dis2}>
            1.人口
          </Link>
        </div>
        <div className={Styles.sogocsstabgridchild2}>
          <Link to={"/106/sogo"} className={Styles.dis2}>
            2.商業
          </Link>
        </div>
        <div className={Styles.sogocsstabgridchild3}>
          <Link to={"/9014/sogo"} className={Styles.dis2}>
            3.交通
          </Link>
        </div>
        <div className={Styles.sogocsstabgridchild4}>
          <Link to={"/9203/sogo"} className={Styles.dis2}>
            4.防災
          </Link>
        </div>
        <div className={Styles.sogocsstabgridchild4}>
          <Link to={"/9019/sogo"} className={Styles.dis2}>
            5.防災
          </Link>
        </div>
      </div>
    </div>



    <div className={Styles.cssgrid}>
      <div className={Styles.sogocssgridchildleft}>
        <br/>
        <HonbunWrapper>
          <div className="brackboard" >TRY!<br/>{post.frontmatter.chyusyaku}{post.frontmatter.hosoku}
          <br/><br/><br/><br/>
          </div>
        </HonbunWrapper>


        <div style={{display:"flex",position:"relative"}}>
          <div style={{width:"100%",margin:"0% 0% 0% 0%",zIndex:999}}>
              <div className={Styles.hanrei}>
                {/* <details open>
                  <summary className={Styles.syousaiheader}> */}
                  {/* <h2>凡例</h2> */}
                  {/* </summary> */}
                    <Hanrei
                      frontmatter={post.frontmatter}
                    />
                {/* </details> */}
              </div>
            </div>
          </div>
        </div>



        
        <div className={Styles.sogocssgridchildright}>
          <div>
            <div>
              <div className={Styles.panel}>
                <div>
                  <CesiumContainer
                    kml_kml={kml_kml}
                    kml_kmz={kml_kmz}
                    // dir="/kml/sample.kml"
                    width="100%"
                    height="80vh"
                    no={post.frontmatter.no}
                    full_name="Full"
                    full_url={"/"+post.frontmatter.no+"/erabuf"}
                  />
                </div>
              </div>
            </div>
          </div>



          <details>
          <summary className={Styles.syousaiheader}>
            <h2>データの詳細情報を確認する</h2>
          </summary>

<div className={Styles.csssyousaigridinner}>
  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.table}>
      <div>
        <h2>高さ</h2>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={Styles.row1}></th>
              <th className={Styles.row2}>解説</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>名称</td>
              <td>{post.frontmatter.height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td>
                <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.tablediv}>
      <div className={Styles.table}>
       <div>
          <h2>色</h2>
          <table>
          <thead>
          <tr>
          <th className={Styles.row1}></th>
          <th className={Styles.row2}>解説</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>名称</td>
          <td>{post.frontmatter.color_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.color_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.color_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td>
            <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>

</details>


<details>
  <summary className={Styles.syousaiheader}>
      <h2>関連するデータを調べる</h2>
  </summary>

  <div className={Styles.csssyousaigridinner}>
    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          タグ
        </h2>
      </div>
      <div>
        {post.frontmatter.tags == null ? null : (
        <div className={Styles.taglinks}>
          {post.frontmatter.tags.map((tag: string, index: number) => (
          <p>
            <Link
            key={index}
            to={`/tags/${_.kebabCase(tag)}/`}
            className={Styles.tag}
            >
              {`${tag}`}
            </Link>
          </p>
          ))}
        </div>
        )}
      </div>
    </div>

    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          関連ページ
        </h2>
      </div>
      <div style={{display:"flex"}}>
        <div style={{margin:"0% 5% 0% 5%"}}>
            {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
            <div key={node.fields.slug}>
              <PostCard
                title={node.frontmatter.title || node.fields.slug}
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                sdgs_no={node.frontmatter.sdgs_no}
                height_name={node.frontmatter.height_name}
                color_name={node.frontmatter.color_name}
              />
            </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  </div>

</details>
</div>
  </div>

</div>
    // </Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlugsogojinko($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_name
        hanrei1_color
        hanrei2_name
        hanrei2_color
        hanrei3_name
        hanrei3_color
        hanrei4_name
        hanrei4_color
        height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

